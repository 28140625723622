<script setup lang="ts">
import { computed, defineProps } from 'vue'

type Props = {
  labels?: string[],
  ignore?: string[]
}
const props = withDefaults(defineProps<Props>(), {
  labels: () => [],
  ignore: () => []
})

const cleanLabels = computed(() => props.labels.filter((label) => !props.ignore.includes(label)))
</script>
<template>
  <div class="flex items-center justify-center flex-row space-x-2" v-if="props.labels && props.labels.length">
    <template v-for="label in cleanLabels" :key="label">
      <span
        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 whitespace-nowrap"
        :class="{
          'bg-purple-100 text-purple-800': label === 'charter',
          'bg-red-100 text-red-800': label === 'sold',
          'bg-green-100 text-green-800': label === 'new',
          'bg-yellow-100 text-yellow-800': label === 'updated',
        }"
      >
        {{ label.toUpperCase() }}
      </span>
    </template>
  </div>
</template>
