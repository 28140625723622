<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { ref } from 'vue'
import { RouterView } from 'vue-router'
import Logo from '../assets/logo.svg'
import LayoutNav from './LayoutNav.vue'

const userNavigation = [{ name: 'Sign out', href: 'https://ahoyclub.kinde.com/logout' }]

const sidebarOpen = ref(false)
</script>

<template>
  <div>
    <div class="lg:hidden" :class="{ 'block': sidebarOpen, 'hidden': !sidebarOpen }">
      <div class="fixed inset-0 bg-gray-900/80 z-50" />
      <div class="relative z-50 lg:hidden">
        <div class="fixed inset-0 flex">
          <div class="relative mr-16 flex w-full max-w-xs flex-1">
            <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
              <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                <span class="sr-only">Close sidebar</span>
                <XMarkIcon class="h-6 w-6 text-white" />
              </button>
            </div>
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-ahoy-club-blue px-6 pb-4">
              <div class="flex h-16 shrink-0 items-center justify-between">
                <a href="/"><img class="h-8 w-auto" :src="Logo" alt="Ahoy Club" /></a>
                <!-- stats -->
                <div class="flex items-center">
                  <ais-stats
                    :class-names="{
                      'ais-Stats': 'text-sm text-gray-700',
                    }"
                  >
                    <template v-slot="{ nbHits }">
                      <div class="whitespace-nowrap justify-center text-white">{{ nbHits }} listings</div>
                    </template>
                  </ais-stats>
                </div>
              </div>
              <LayoutNav />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TransitionRoot as="template" :show="sidebarOpen">
      <Dialog class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
                <div class="flex h-16 shrink-0 items-center">
                  <a href="/"><img class="h-8 w-auto" :src="Logo" alt="Ahoy Club" /></a>
                </div>
                <LayoutNav />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot -->

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-ahoy-club-blue px-6 pb-4">
        <div class="flex h-16 shrink-0 items-center justify-between">
          <a href="/"><img class="h-8 w-auto" :src="Logo" alt="Ahoy Club" /></a>
          <!-- stats -->
          <div class="flex items-center">
            <ais-stats
              :class-names="{
                'ais-Stats': 'text-sm text-gray-700',
              }"
            >
              <template v-slot="{ nbHits }">
                <div class="whitespace-nowrap justify-center text-white">{{ nbHits }} listings</div>
              </template>
            </ais-stats>
          </div>
        </div>
        <LayoutNav />
      </div>
    </div>

    <div class="lg:pl-72">
      <div
        class="sticky top-0 z-40 flex h-[89px] md:h-[49px] shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white shadow-sm sm:gap-x-6 px-2 sm:px-4 lg:px-6"
      >
        <button
          type="button"
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

        <div class="flex w-full h-full">
          <RouterView name="header" v-bind="$props" />
        </div>

        <div
          class="flex flex-row items-center gap-x-4 lg:gap-x-6 flex-wrap md:flex-nowrap justify-end md:justify-center"
        >
          <!-- Separator -->
          <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

          <!-- Profile dropdown -->
          <Menu as="div" class="relative">
            <MenuButton class="-m-1.5 flex items-center p-1.5">
              <span class="sr-only">Open user menu</span>
              <span class="flex lg:items-center">
                <!-- prettier-ignore -->
                <span class="text-sm font-semibold leading-6 text-gray-900 whitespace-nowrap" aria-hidden="true">{{ $user.given_name || 'You' }}</span>
                <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
              >
                <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                  <a
                    :href="item.href"
                    class="duration-200"
                    :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']"
                    >{{ item.name }}</a
                  >
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>

      <!-- Main Content Area -->
      <div class="h-[calc(100vh-64px)] overflow-auto">
        <RouterView name="content" v-bind="$props" />
      </div>
    </div>
  </div>
</template>
