import { DateTime } from 'luxon'
import { logger } from '@beacon/common/lang/log'

const log = logger({ package: 'common/lang', f: 'util' })

/**
 * Convert a digest buffer to a hex string
 *
 * @param digest the digest
 * @returns the hex encoded string
 */
export const digestAsHex = (digest: ArrayBuffer) =>
  [...new Uint8Array(digest)].map((b) => b.toString(16).padStart(2, '0')).join('')

/**
 * Decode base64 to string
 *
 * @param str
 * @returns
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 */
export const decodeBase64 = (str: string): string => {
  const binString: any = atob(str)
  const u = Uint8Array.from(binString, (m: any) => m.codePointAt(0))
  return new TextDecoder().decode(u)
}

/**
 * Round a number to a given number of fraction digits
 *
 * @param n the number
 * @param fractionDigits how many digits (default 2)
 * @returns a number rounded or undefined
 */
export const round = (n: number | string, fractionDigits: number = 2): number | undefined => {
  log.trace({ n, fractionDigits }, 'round')

  let _n: number
  // sometimes data is bad, so we need to check for string
  if (typeof n === 'string') {
    // tricky this is -- what should we remove before we try to parse?
    const nStr = n.replace(',', '')
    _n = Number.parseFloat(nStr)
  } else {
    _n = n
  }

  if (!_n) {
    return undefined
  }

  if (typeof _n === 'number' && isNaN(_n)) {
    return undefined
  }

  return Number.parseFloat(_n.toFixed(fractionDigits))
}

/**
 * Convert an ISO formatted date string to epoch seconds
 *
 * @param d an ISO formatted date string
 * @returns the epoch seconds, or undefined if supplied parameter is undefined or can not be parsed.
 */
export const toEpochSeconds = (d: string | undefined): number | undefined => {
  log.trace({ d }, 'toEpochSeconds')
  if (!d || d.length === 0) {
    return undefined
  }
  //
  try {
    return round(DateTime.fromISO(d).toSeconds(), 0)
  } catch (e) {
    return undefined
  }
}

/**
 * Generate a random number between the given range
 *
 * @param min the minimum number (inclusive)
 * @param max the maximum number (exclusive)
 * @returns a random number where return >= min and return < max
 */
export const randomBetween = (min: number, max: number) =>
  Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min)) + Math.ceil(min))
