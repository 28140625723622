import { createApp } from 'vue'
import InstantSearch from 'vue-instantsearch/vue3/es'
import VueObserveVisibility from 'vue-observe-visibility'

import { useKindeAuthentication } from './useKindeAuthentication'
import router from './router'

import { createPinia } from 'pinia'
import App from './App.vue'
import './style.css'

const Kinde = await useKindeAuthentication({
  client_id: '72d1961dccc94106b1de8be6c8e0d8f5',
  redirect_uri: `${window.location.origin}/auth/callback`,
  domain: 'https://ahoyclub.kinde.com',
  org_code: 'org_53156f1b9b3',
  logout_uri: `${window.location.origin}/`,
  is_dangerously_use_local_storage: true,
})

// check on the token to ensure they are logged in.
const _token = await Kinde.getToken()
if (!_token) {
  // redirect to login if they are not.
  Kinde.redirectToLogin()
} else {
  const app = createApp(App)
  app.use(createPinia())
  app.use(router)
  app.use(Kinde)
  app.use(InstantSearch)
  app.use(VueObserveVisibility)
  app.mount('#app')
  app.config.globalProperties.window = window
}
