import { logger } from '@beacon/common/lang/log'

const log = logger({ package: 'provider', provider_name: 'ankor', f: 'followupAll' })

/**
 * Convert parameters to a URL.
 *
 * @param uri
 * @param src
 * @param variant
 * @returns
 */
export const imageUrl = (uri: string, src: string, variant: string = '1280w'): string => {
  log.trace({ uri, src, variant }, 'imageUrl')
  if (!src) {
    return src
  }
  //
  else if (src.startsWith('media::')) {
    return `https://api.ankor.io/media/${src}/${variant}`
  }
  //
  else if (src.startsWith('assets/')) {
    return `https://api.ankor.io/media/media::image::${uri}::${src.replaceAll('/', '::')}/${variant}`
  }

  return src
}
