<script setup lang="ts">
//
// * The main view of the application is search results.
//
import { round } from '@beacon/common/lang/util'
import { Price } from '@beacon/common/types'
import { getProviderLabel } from '@beacon/listings/services'
import { watchDebounced } from '@vueuse/core'
import { DateTime } from 'luxon'
import aa from 'search-insights'
import { computed, onBeforeUpdate, ref, Ref } from 'vue'
import { priceShort, setBrokenImagePlaceholder } from '../helpers'
import Badges from './Badges.vue'
import ListingDetailView from './ListingDetailView.vue'
import SearchResultsObserver from './SearchResultsObserver.vue'

type Props = {
  navFilter: string
}
const props = defineProps<Props>()

// state tracking for the expanded rows
const expandos = ref([] as any)
const showCopySuccess = ref(false)

onBeforeUpdate(() => {
  expandos.value = []
})

const expando = (_e: Event, uri: string) => {
  // console.log(uri)
  const row = expandos.value[uri] as HTMLElement
  row.classList.toggle('hidden')
}
const isRowOpen = (uri: string) => {
  const row = expandos.value[uri] as HTMLElement
  return !row.classList.contains('hidden')
}

const showCopyToast = () => {
  showCopySuccess.value = true
  setTimeout(() => {
    showCopySuccess.value = false
  }, 3000)
}

//
// manage the filter that is supplied via the route
const numericFilters = computed(() => {
  const epoch30DaysAgo = DateTime.utc().minus({ days: 30 }).toUnixInteger()
  switch (props.navFilter) {
    case 'new':
      return [`tsListingPublished>=${epoch30DaysAgo}`]
    case 'updated':
      return [`tsListingUpdated>=${epoch30DaysAgo}`]
    //
    case 'all':
    default:
      return []
  }
})
const facetFilters = computed(() => {
  switch (props.navFilter) {
    case 'for-sale':
      return [`labels:"for sale"`]
    case 'charter':
      return [`labels:charter`]
    case 'sold':
      return [`labels:sold`]
    //
    case 'all':
    default:
      return []
  }
})

const queryID: Ref<string> = ref('')

// watch the visible rows array (those that became visible) and send the analytics event.
const rowVisibleObjectIds: Ref<string[]> = ref([])
watchDebounced(
  rowVisibleObjectIds,
  () => {
    if (rowVisibleObjectIds.value.length > 0) {
      aa('viewedObjectIDs', {
        index: 'global',
        eventName: 'row became visible',
        objectIDs: rowVisibleObjectIds.value,
      })
      rowVisibleObjectIds.value = []
    }
  },
  { debounce: 500, maxWait: 1000, deep: true },
)

const searchFilter = computed(() => {
  return [...numericFilters.value, ...facetFilters.value].join(' AND ')
})

let dirtyRiver: Ref<any[]> = ref([])
const river = computed(() => dirtyRiver.value.filter((item) => !!item))

//
// accumulate the results in the river array.
const accumulateSearchResults = (items: any[], { results }: any): object => {
  //  ** IMPORTANT **
  //  There is no guarantee that this function will be called only once
  //    for each call to refineNext() or via an Algolia HTTP call. See:
  //   - https://github.com/algolia/vue-instantsearch/issues/707#issuecomment-1361526922
  //

  //
  const prevQueryID = queryID.value
  if (results.queryID) {
    queryID.value = results.queryID
  } else {
    queryID.value = ''
  }

  // reset river to empty array if it's the first page
  if (prevQueryID !== queryID.value || results.page === 0) {
    dirtyRiver.value = []
    // FIXME: TODO: integrate this into a higher level.
    window.document.getElementById('content-top')?.scrollIntoView({ behavior: 'auto', block: 'end' })
  }

  items.forEach((hit, i) => {
    //
    let offset = results.page * results.hitsPerPage + i
    // set the hit to the expected row position aligning to the position of the hit.
    dirtyRiver.value[offset] = {
      offset,
      ...hit,
      // some indexed items have their provider key in the 'provider id'; These will fall out over time.
      providerId: hit.providerId?.replace(new RegExp(`^${hit.provider}::`), ''),
      // some indexed items have the full URI on the brochure; These will fall out over time.
      brochureId: hit.brochureId?.replace(/^beacon::brochure::/, ''),
      // convert the location to an array if it is not.
      ...(hit.location && !Array.isArray(hit.location) && { location: [hit.location] }),
      // convert the price to an array if it is not.
      ...(hit.priceAsking && { price: [{ label: 'Price Asking', ...hit.priceAsking }] }),
    }
  })

  // return the clean river (used by algolia lib)
  return river
}
</script>
<template>
  <div>
    <ais-configure :hits-per-page.camel="50" :filters="searchFilter" :clickAnalytics="true" />
    <ais-infinite-hits :transform-items="accumulateSearchResults" v-bind="$attrs">
      <template v-slot="{ refineNext, isLastPage, results }">
        <template v-if="!results.nbHits">
          <slot name="no-results">
            <div class="flex items-center justify-center h-full">
              <h4 class="font-bold text-center text-black dark:text-white">No results found.</h4>
            </div>
          </slot>
        </template>
        <template v-else>
          <div class="h-[calc(100vh-64px)] w-full overflow-y-auto flex flex-col">
            <table
              class="w-full text-sm text-left text-gray-800 table-auto 3xl:table-fixed relative overflow-y-auto mx-auto"
            >
              <thead class="text-xs uppercase">
                <tr>
                  <!-- prettier-ignore -->
                  <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 md:w-[500px]">Yacht</th>
                  <!-- prettier-ignore -->
                  <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-left">Source</th>
                  <!-- prettier-ignore -->
                  <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-2 text-right">Price</th>
                  <!-- prettier-ignore -->
                  <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-right whitespace-nowrap">Length</th>
                  <!-- prettier-ignore -->
                  <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-left">Builder</th>
                  <!-- prettier-ignore -->
                  <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-right">Cabins</th>
                  <!-- prettier-ignore -->
                  <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-right">Built</th>
                  <!-- prettier-ignore -->
                  <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-24 text-right">Refit</th>
                  <!-- prettier-ignore -->
                  <th scope="col" class="bg-ahoy-club-blue text-white font-medium sticky top-0 px-4 py-3 min-w-40 text-left md:w-96">Location &amp; Broker</th>
                </tr>
              </thead>
              <tbody class="overflow-y-scroll w-full">
                <tr id="content-top" class="h-px">
                  <td><!-- marker for the top of the content --></td>
                </tr>
                <template v-for="hit in river" :key="hit.objectID">
                  <tr
                    class="w-full border-b cursor-pointer transition [&:nth-child(4n+3)]:bg-gray-50 [&:nth-child(4n+3)]:hover:bg-gray-200 hover:bg-gray-200 duration-200"
                    aria-expanded="false"
                    :id="`header-${hit.objectID}`"
                    :aria-controls="`details-${hit.objectID}`"
                    @click="
                      [
                        expando($event, hit.objectID),
                        isRowOpen(hit.objectID) &&
                          aa('clickedObjectIDsAfterSearch', {
                            index: 'global',
                            eventName: 'row clicked',
                            objectIDs: [hit.objectID],
                            queryID,
                            positions: [hit.offset],
                          }),
                      ]
                    "
                    v-observe-visibility="(isVisible: boolean) => isVisible && rowVisibleObjectIds.push(hit.objectID)"
                  >
                    <th scope="row" class="w-full px-4 py-3 font-medium text-gray-900">
                      <div class="flex flex-row">
                        <div class="">
                          <img
                            v-if="hit.thumbnail"
                            class="h-8 w-16 min-w-16 mr-2 object-cover rounded-sm hover:scale-150 duration-300"
                            :src="`${hit.thumbnail}`"
                            alt=""
                            @error="setBrokenImagePlaceholder($event)"
                          />
                          <img
                            v-else
                            class="h-8 w-16 min-w-16 mr-2 object-cover rounded-sm hover:scale-150 duration-300"
                            :src="`/broken-placeholder.ignore`"
                            @error="setBrokenImagePlaceholder($event)"
                            alt=""
                          />
                        </div>
                        <div class="grow whitespace-nowrap truncate">
                          <span class="text-bold"> {{ hit.buildYear }} {{ hit.model }} </span><br />
                          <span class="text-xs uppercase text-gray-600"> {{ hit.name }}</span>
                        </div>
                        <!-- Badges -->
                        <Badges v-if="props.navFilter === 'for-sale'" :labels="hit.labels" :ignore="['for sale']" />
                        <Badges v-else-if="props.navFilter === 'charter'" :labels="hit.labels" :ignore="['charter']" />
                        <Badges v-else :labels="hit.labels" />
                      </div>
                    </th>
                    <!-- Source -->
                    <td class="px-4 py-3 text-left">
                      <span v-html="getProviderLabel(hit.provider) || '&mdash;'"></span>
                    </td>
                    <!-- Price -->
                    <td class="px-4 py-3 text-right whitespace-nowrap">
                      <div v-if="hit.price?.some((p: Price) => p.label === 'min')">
                        From:
                        <span v-html="priceShort(hit.price.find((e: any) => e.label === 'min')) || '&mdash;'"></span>
                      </div>
                      <div v-if="hit.price?.some((p: Price) => p.label === 'max')">
                        To:
                        <span v-html="priceShort(hit.price.find((e: any) => e.label === 'max')) || '&mdash;'"></span>
                      </div>
                      <span v-if="hit.price?.length == 1" v-html="priceShort(hit.price[0]) || '&mdash;'"></span>
                    </td>
                    <!-- Length -->
                    <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap text-right">
                      <span
                        v-html="
                          round(hit.length, 1) + 'm (' + (hit.length * 3.2808398950131).toFixed(0) + 'ft)' || '&mdash;'
                        "
                      ></span>
                      <!-- {{ (hit.length * 3.2808398950131).toFixed(0) }} ft -->
                    </td>
                    <!-- Builder -->
                    <td class="px-4 py-3 text-left">
                      <span>{{ hit.builder || '&mdash;' }}</span>
                    </td>
                    <!-- Cabins -->
                    <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap text-right">
                      {{ hit.cabins || '&mdash;' }}
                    </td>
                    <!-- Built -->
                    <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap text-right">
                      {{ hit.buildYear || '&mdash;' }}
                    </td>
                    <!-- Refit -->
                    <td class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap text-right">
                      {{ hit.refitYear || '&mdash;' }}
                    </td>
                    <!-- Location -->
                    <td class="px-4 py-3 font-medium text-gray-900 text-left">
                      <div class="grid grid-cols-1 gap-1">
                        <div class="grow whitespace-wrap truncate" v-for="loc in hit.location?.slice(0, 1)">
                          {{ loc }}
                        </div>
                        <template v-if="hit.location?.length > 2">
                          <div class="grow whitespace-wrap truncate text-gray-500">
                            +{{ hit.location?.length - 1 }} more
                          </div>
                        </template>
                        <template v-else>
                          <div class="grow whitespace-wrap truncate" v-for="loc in hit.location?.slice(1, 2)">
                            {{ loc }}
                          </div>
                        </template>
                      </div>
                      <!-- Company -->
                      <span v-if="hit.broker?.company" class="truncate">{{ hit.broker?.company }}</span>
                    </td>
                  </tr>
                  <!-- expando details-->
                  <tr
                    class="hidden flex-1 overflow-x-auto w-full border-b"
                    :id="`details-${hit.objectID}`"
                    :ref="
                      (el) => {
                        expandos[hit.objectID] = el
                      }
                    "
                    :aria-labelledby="`header-${hit.objectID}`"
                  >
                    <td colspan="100%">
                      <ListingDetailView :hit="hit" :queryID="queryID" @showCopySuccess="showCopyToast()" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <template v-if="!isLastPage && !dirtyRiver.length">...</template>
            <template v-else-if="!isLastPage">
              <SearchResultsObserver @visible="refineNext" />
            </template>
            <template v-else="isLastPage">
              <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
            </template>
          </div>
        </template>
      </template>
    </ais-infinite-hits>
  </div>
</template>
