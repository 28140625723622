import { logger } from '@beacon/common/lang/log'
import { AssetMap, BrochureListing, Chronicle, ListingInfo } from '@beacon/common/types'
import { BoatsOnlineListing } from './types'

const log = logger({ package: 'provider', provider_name: 'boats-online', f: 'asBrochureListing' })

/**
 *
 * @param content
 * @returns
 */
export const asBrochureListing = (
  listing: BoatsOnlineListing,
  _listingInfo: ListingInfo,
  _chronicle: Chronicle,
  assetMap: AssetMap,
): BrochureListing => {
  //
  // NB: Keep the type shape for clarity in the code.
  const brochureListing: BrochureListing = {
    name: listing.VesselName?.trim() || listing.MakeModel,
    title: listing.VesselName?.trim() || listing.MakeModel,
    //
    accommodation: {
      accommodation: listing.Accomodation,
    },
    entertainment_and_av_equipment: [listing.Electrics, listing.Electronics, listing.Entertainment]
      .filter((e) => e)
      .join(', '),
    galley_and_laundry_equipment: listing.Galley,
    images: assetMap.map(listing.Resources.Image).filter((e) => e !== undefined),
    new_listing: listing.new_boat ? new Boolean(listing.new_boat).valueOf() : undefined,
    safety_and_security_equipment: listing.SafetyGear,
    sold_banner: listing.sold === 'Sold',
    //
    // broker_details: {
    //   image: undefined,
    //   is_broker: undefined,
    //   job_title: undefined,
    //   name: undefined,
    //   personal_blurb: undefined,
    //   phone_number: undefined,
    // },
    hull_and_deck: {
      // date_keel_laid: undefined,
      deck_material: listing.DecksMaterial,
      // exterior_designer: undefined,
      hin: listing.HIN,
      // hull_colour: undefined,
      // hull_configuration: undefined,
      // hull_designer: undefined,
      hull_material: listing.HullMaterial,
      // hull_number: undefined,
      // interior_designer: undefined,
      // superstructure_material: undefined,
      // warranty: undefined,
    },
    main_spec: {
      length_meters: listing.Length ? listing.Length : 0,
      model: listing.boatmodel ? listing.boatmodel : '',
      price: listing.Price ? `${listing.Currency}${listing.Price}` : 'POA',
      //
      beam: listing.Beam ? new Number(listing.Beam).valueOf() : undefined,
      builder: listing.Builder,
      cabins: listing.Cabins || 0,
      // type: undefined,
      // classification: undefined,
      // construction: undefined,
      // crew: undefined,
      // decks: undefined,
      description: listing.descript,
      // detailed_description: undefined,
      draft_length: listing.Draft ? new Number(listing.Draft).valueOf() : undefined,
      exterior_designer: listing.Designer,
      // flag: undefined,
      // gross_tonnage: undefined,
      // imo_number: undefined,
      interior_designer: listing.Designer,
      // ism: undefined,
      lying: listing.Location,
      main_image: assetMap.pluck(listing.Thumbnail),
      // mci: undefined,
      // mmsi_number: undefined,
      // refit_type: undefined,
      // sleep: undefined,
      // specifications: undefined,
      // vessel_top: undefined,
      year_built: listing.Year || 0,
      // year_refit: undefined,
    },
    mechanical_equipment: {
      // air_compressor: undefined,
      // air_conditioning: undefined,
      // antifouling_system: undefined,
      // batteries: undefined,
      // battery_chargers: undefined,
      bow_thruster: listing.Thrusters,
      // dirty_oil_pump: undefined,
      // emergency_generator: undefined,
      // engine_controls: undefined,
      // engine_mounts: undefined,
      // engine_room_ventilation: undefined,
      // engine_ventilation: undefined,
      // engines_exhaust_system: undefined,
      // fresh_water_makers: undefined,
      // fresh_water_pressure: undefined,
      // fresh_water_treatment: undefined,
      // fuel_filling_pump: undefined,
      // fuel_filters: undefined,
      // fuel_filtration: undefined,
      // fuel_oil_separator: undefined,
      // fuel_transfer_pump: undefined,
      // gear_boxes: undefined,
      // generator_hours: undefined,
      // generators: undefined,
      // grey_water_holding_tanks: undefined,
      // grey_water_system: undefined,
      // hot_and_cold_circulation_pump: undefined,
      // lubrication: undefined,
      // oil_filtration: undefined,
      // oil_pump: undefined,
      // output_electricity: undefined,
      // propellers: undefined,
      propulsion_type: listing.Propulsion,
      // rudders: undefined,
      // sewage_system: undefined,
      // shaft_log_and_acking_glands: undefined,
      // shafting: undefined,
      // shore_power: undefined,
      // stabilizers: undefined,
      // steering_system: undefined,
      stern_thruster: listing.Thrusters,
      // under_water_lights: undefined,
      // wc: undefined,
      engine_1: {
        approx_hours: `${listing.EngineHours}`,
        // date_hours_reg: undefined,
        fuel: listing.FuelType,
        horsepower: listing.HorsePower,
        // last_service: undefined,
        // manufacturer: undefined,
        model: listing.EngineMake,
        // serial: undefined,
        // type: undefined,
      },
      engine_2: {
        approx_hours: `${listing.EngineHours2}`,
        // date_hours_reg: undefined,
        fuel: listing.FuelType,
        horsepower: listing.HorsePower,
        // last_service: undefined,
        // manufacturer: undefined,
        model: listing.EngineMake,
        // serial: undefined,
        // type: undefined,
      },
    },
    navicom_equipment: {
      //   ais: undefined,
      //   auto_pilot: undefined,
      //   charts: undefined,
      //   depth_sounder: undefined,
      //   dgps: undefined,
      //   echo_sounder: undefined,
      //   electronic_chart_system: undefined,
      //   eprib: undefined,
      //   gdmss: undefined,
      //   gps: undefined,
      //   gyrocompass: undefined,
      //   horn: undefined,
      //   inmarsat_c: undefined,
      //   log: undefined,
      //   magnetic_compass: undefined,
      //   navigation_bridge: undefined,
      //   navtex: undefined,
      //   radar: undefined,
      //   radio: undefined,
      //   rudder_angle_indicator_system: undefined,
      //   satcom: undefined,
      //   search_lights: undefined,
      //   ships_computer: undefined,
      //   ships_monitoring: undefined,
      //   ssb: undefined,
      //   telephone_system: undefined,
      //   tender_tracking_system: undefined,
      //   vdr: undefined,
      //   vhf: undefined,
    },
    speed_capacity_and_weight: {
      cruise_speed: listing.CruiseSpeed ? new Number(listing.CruiseSpeed).valueOf() : undefined,
      // cruise_speed_range: undefined,
      // dirty_lube_oil: undefined,
      fresh_water_capacity: listing.WaterCapacity,
      fuel_capacity: listing.FuelCapacity,
      // fuel_consumption: undefined,
      // grey_black_water_capacity: undefined,
      // lube_oil: undefined,
      max_speed: listing.MaxSpeed ? new Number(listing.MaxSpeed).valueOf() : undefined,
      // waster_oil: undefined,
    },
  }

  log.debug({ brochureListing }, 'asBrochureListing result')
  return brochureListing
}
