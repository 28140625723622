import { createRouter, createWebHistory } from 'vue-router'
import Layout from './views/Layout.vue'
import LayoutHeader from './views/LayoutHeader.vue'
import MainView from './views/MainView.vue'
import StatusQueues from './views/StatusQueues.vue'
import StatusLayout from './views/StatusLayout.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/auth/callback',
      name: 'auth-callback',
      component: () => import('./views/Loading.vue'),
    },
    {
      path: '/',
      redirect: '/for-sale/',
      components: {
        layout: Layout,
      },
      children: [
        {
          path: 'for-sale/',
          name: 'home',
          components: {
            header: LayoutHeader,
            content: MainView,
          },
          props: { content: { navFilter: 'for-sale' } },
        },
        {
          path: 'new/',
          name: 'new',
          components: {
            header: LayoutHeader,
            content: MainView,
          },
          props: { content: { navFilter: 'new' } },
        },
        {
          path: 'updated/',
          name: 'updated',
          components: {
            header: LayoutHeader,
            content: MainView,
          },
          props: { content: { navFilter: 'updated' } },
        },
        {
          path: 'sold/',
          name: 'sold',
          components: {
            header: LayoutHeader,
            content: MainView,
          },
          props: { content: { navFilter: 'sold' } },
        },
        {
          path: 'charter/',
          name: 'charter',
          components: {
            header: LayoutHeader,
            content: MainView,
          },
          props: { content: { navFilter: 'charter' } },
        },
        {
          path: 'all/',
          name: 'all',
          components: {
            header: LayoutHeader,
            content: MainView,
          },
          props: { content: { navFilter: 'all' } },
        },
      ],
    },
    {
      path: '/status/',
      redirect: '/status/queues',
      components: {
        layout: StatusLayout,
      },
      children: [
        {
          path: 'queues',
          name: 'status-queues',
          components: {
            content: StatusQueues,
          },
          props: {},
        },
      ],
    },
    // 404 page configuration by path matching
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('./views/404.vue'),
    },
    // Configure me for 401
    // {
    //   path: '/unauthorized',
    //   name: 'unauthorized',
    //   component: () => import('-----'),
    // },
  ],
  scrollBehavior(_to, _from, _savedPosition) {
    return {
      // always scroll to top on any route change.
      top: 0,
      behavior: 'smooth',
    }
  },
})

export default router
