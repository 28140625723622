import { logger } from '@beacon/common/lang/log'
import { AssetMap, BrochureListing, Chronicle, ListingInfo } from '@beacon/common/types'
import { BoatsListing } from './types'

const log = logger({ package: 'provider', provider_name: 'boats', f: 'asBrochureListing' })

/**
 *
 * @param content
 * @returns
 */
export const asBrochureListing = (
  listing: BoatsListing,
  _listingInfo: ListingInfo,
  _chronicle: Chronicle,
  assetMap: AssetMap,
): BrochureListing => {
  //
  log.trace({ listing }, 'incoming listing content')

  // NB: Keep the type shape for clarity in the code.
  const brochureListing: BrochureListing = {
    name: listing.BoatName || `${listing.ModelYear} ${listing.MakeString}`,
    title: listing.ListingTitle,
    //
    // accommodation: {
    //   accommodation: undefined,
    // },
    // entertainment_and_av_equipment: undefined,
    // galley_and_laundry_equipment: undefined,
    images: assetMap.map(listing.Images.map((image) => image.Uri)).filter((e) => e !== undefined),
    // new_listing: undefined,
    // safety_and_security_equipment: undefined,
    // sold_banner: undefined,
    //
    broker_details: {
      // image: undefined,
      // is_broker: undefined,
      // job_title: undefined,
      name: listing.SalesRep.Name,
      // personal_blurb: undefined,
      // phone_number: undefined,
    },
    hull_and_deck: {
      // date_keel_laid: undefined,
      // deck_material: undefined,
      // exterior_designer: undefined,
      hin: listing.BoatHullID,
      // hull_colour: undefined,
      // hull_configuration: undefined,
      // hull_designer: undefined,
      hull_material: listing.BoatHullMaterialCode,
      // hull_number: undefined,
      // interior_designer: undefined,
      // superstructure_material: undefined,
      // warranty: undefined,
    },
    main_spec: {
      length_meters: listing.NormNominalLength ? listing.NormNominalLength : 0,
      model: listing.Model ? listing.Model : '',
      price: listing.Price ? `${listing.OriginalPrice}` : 'POA',
      //
      beam: listing.BeamMeasure ? new Number(listing.BeamMeasure).valueOf() : undefined,
      builder: listing.BuilderName,
      // cabins: undefined,
      type: listing.BoatCategoryCode,
      // classification: undefined,
      // construction: undefined,
      // crew: undefined,
      // decks: undefined,
      description: listing.GeneralBoatDescription[0],
      // detailed_description: undefined,
      // draft_length: undefined,
      exterior_designer: listing.DesignerName,
      flag: listing.BoatLocation.BoatStateCode,
      // gross_tonnage: undefined,
      // imo_number: undefined,
      interior_designer: listing.DesignerName,
      // ism: undefined,
      lying: listing.BoatLocation.BoatStateCode,
      main_image: assetMap.pluck(listing.Images[0].Uri),
      // mci: undefined,
      // mmsi_number: undefined,
      // refit_type: undefined,
      // sleep: undefined,
      // specifications: undefined,
      // vessel_top: undefined,
      year_built: listing.ModelYear,
      // year_refit: undefined,
    },
    // mechanical_equipment: {
    //   air_compressor: undefined,
    //   air_conditioning: undefined,
    //   antifouling_system: undefined,
    //   batteries: undefined,
    //   battery_chargers: undefined,
    //   bow_thruster: undefined,
    //   dirty_oil_pump: undefined,
    //   emergency_generator: undefined,
    //   engine_controls: undefined,
    //   engine_mounts: undefined,
    //   engine_room_ventilation: undefined,
    //   engine_ventilation: undefined,
    //   engines_exhaust_system: undefined,
    //   fresh_water_makers: undefined,
    //   fresh_water_pressure: undefined,
    //   fresh_water_treatment: undefined,
    //   fuel_filling_pump: undefined,
    //   fuel_filters: undefined,
    //   fuel_filtration: undefined,
    //   fuel_oil_separator: undefined,
    //   fuel_transfer_pump: undefined,
    //   gear_boxes: undefined,
    //   generator_hours: undefined,
    //   generators: undefined,
    //   grey_water_holding_tanks: undefined,
    //   grey_water_system: undefined,
    //   hot_and_cold_circulation_pump: undefined,
    //   lubrication: undefined,
    //   oil_filtration: undefined,
    //   oil_pump: undefined,
    //   output_electricity: undefined,
    //   propellers: undefined,
    //   propulsion_type: undefined,
    //   rudders: undefined,
    //   sewage_system: undefined,
    //   shaft_log_and_acking_glands: undefined,
    //   shafting: undefined,
    //   shore_power: undefined,
    //   stabilizers: undefined,
    //   steering_system: undefined,
    //   stern_thruster: undefined,
    //   under_water_lights: undefined,
    //   wc: undefined,
    //   engine_1: {
    //     approx_hours: undefined,
    //     date_hours_reg: undefined,
    //     fuel: undefined,
    //     horsepower: undefined,
    //     last_service: undefined,
    //     manufacturer: undefined,
    //     model: undefined,
    //     serial: undefined,
    //     type: undefined,
    //   },
    //   engine_2: {
    //     approx_hours: undefined,
    //     date_hours_reg: undefined,
    //     fuel: undefined,
    //     horsepower: undefined,
    //     last_service: undefined,
    //     manufacturer: undefined,
    //     model: undefined,
    //     serial: undefined,
    //     type: undefined,
    //   },
    // },
    // navicom_equipment: {
    //   ais: undefined,
    //   auto_pilot: undefined,
    //   charts: undefined,
    //   depth_sounder: undefined,
    //   dgps: undefined,
    //   echo_sounder: undefined,
    //   electronic_chart_system: undefined,
    //   eprib: undefined,
    //   gdmss: undefined,
    //   gps: undefined,
    //   gyrocompass: undefined,
    //   horn: undefined,
    //   inmarsat_c: undefined,
    //   log: undefined,
    //   magnetic_compass: undefined,
    //   navigation_bridge: undefined,
    //   navtex: undefined,
    //   radar: undefined,
    //   radio: undefined,
    //   rudder_angle_indicator_system: undefined,
    //   satcom: undefined,
    //   search_lights: undefined,
    //   ships_computer: undefined,
    //   ships_monitoring: undefined,
    //   ssb: undefined,
    //   telephone_system: undefined,
    //   tender_tracking_system: undefined,
    //   vdr: undefined,
    //   vhf: undefined,
    // },
    speed_capacity_and_weight: {
      cruise_speed: listing.CruisingSpeedMeasure ? new Number(listing.CruisingSpeedMeasure).valueOf() : undefined,
      cruise_speed_range: listing.RangeMeasure,
      // dirty_lube_oil: undefined,
      // fresh_water_capacity: undefined,
      // fuel_capacity: undefined,
      // fuel_consumption: undefined,
      // grey_black_water_capacity: undefined,
      // lube_oil: undefined,
      max_speed: listing.MaximumSpeedMeasure ? new Number(listing.MaximumSpeedMeasure).valueOf() : undefined,
      // waster_oil: undefined,
    },
  }

  return brochureListing
}
