import { logger } from '@beacon/common/lang/log'
import { AssetMap, BrochureListing, Chronicle, ListingInfo } from '@beacon/common/types'
import { YatcoListing } from './types'
import { extractAssetUrls } from './extractAssets'

const log = logger({ package: 'provider', provider_name: 'yatco', f: 'asBrochureListing' })

/**
 *
 * @param content
 * @returns
 */
export const asBrochureListing = (
  listing: YatcoListing,
  _listingInfo: ListingInfo,
  _chronicle: Chronicle,
  assetMap: AssetMap,
): BrochureListing => {
  //
  log.trace({ listing }, 'incoming listing content')

  const brochureListing = {
    //
    name: listing.BasicInfo.BoatName,
    title: listing.BasicInfo.BoatName,
    //
    // auction_banner: undefined,
    // auction_date: undefined,
    // auction_location: undefined,
    // best_buy: undefined,
    // bidding_link: undefined,
    images: assetMap.map(extractAssetUrls(listing)),
    // is_central_agency: undefined,
    // new_listing: undefined,
    // price_drop: undefined,
    sold_banner: listing.BasicInfo.SoldPrice ? true : undefined,
    accommodation: {
      // accommodation: undefined,
      berthsValue: listing.Accommodations.BerthsValue,
      headsValue: listing.Accommodations.HeadsValue,
      headRoomValue: listing.Accommodations.HeadRoomValue,
      sleepsValue: listing.Accommodations.SleepsValue,
      stateRoomsValue: listing.Accommodations.StateRoomsValue,
      crewQuartersValue: listing.Accommodations.CrewQuartersValue,
      crewBerthsValue: listing.Accommodations.CrewBerthsValue,
      crewSleepsValue: listing.Accommodations.CrewSleepsValue,
      crewHeadsValue: listing.Accommodations.CrewHeadsValue,
      captainQuarters: listing.Accommodations.CaptainQuarters,
    },
    // entertainment_and_av_equipment: undefined,
    // galley_and_laundry_equipment: undefined,
    // safety_and_security_equipment: undefined,
    // refits_and_additional: undefined,
    // toys_and_tenders: undefined,
    weight: listing.SpeedWeight.GrossTonnageValue,
    //
    // broker_details: {
    //   image: undefined,
    //   is_broker: undefined,
    //   job_title: undefined,
    //   name: undefined,
    //   personal_blurb: undefined,
    //   phone_number: undefined,
    // },
    hull_and_deck: {
      // date_keel_laid: undefined,
      deck_material: listing.HullDeck.DeckMaterial,
      exterior_designer: listing.HullDeck.ExteriorDesigner,
      hin: listing.HullDeck.HullID,
      hull_colour: listing.HullDeck.HullColor,
      hull_configuration: listing.HullDeck.HullConfiguration,
      hull_designer: listing.HullDeck.HullDesigner,
      hull_material: listing.HullDeck.HullMaterial,
      hull_number: listing.HullDeck.HullID,
      interior_designer: listing.HullDeck.InteriorDesigner,
      superstructure_material: listing.HullDeck.SuperstructureMaterial,
      // warranty: undefined,
    },
    main_spec: {
      length_meters: listing.Dimensions.Length,
      model: listing.BasicInfo.Model,
      price: listing.BasicInfo.AskingPriceFormatted,
      beam: listing.Dimensions.BeamValue,
      builder: listing.BasicInfo.Builder,
      cabins: listing.BasicInfo.StateRooms,
      type: listing.Result.MainCategoryText,
      // classification: undefined,
      construction: listing.HullDeck.HullMaterial,
      crew: listing.Accommodations.CrewSleepsValue,
      // decks: undefined,
      clearance: listing.Dimensions.Clearance,
      description: listing.VD.VesselDescriptionShortDescriptionNoStyles,
      detailed_description: listing.Sections,
      draft_length: listing.Dimensions.MinDraftValue,
      exterior_designer: listing.HullDeck.ExteriorDesigner,
      flag: listing.BasicInfo.Flag,
      gross_tonnage: listing.SpeedWeight.GrossTonnageValue,
      // imo_number: undefined,
      interior_designer: listing.HullDeck.InteriorDesigner,
      // ism: undefined,
      lying: [listing.BasicInfo.LocationCity, listing.BasicInfo.LocationCountry].filter((e) => e).join(', '),
      main_image: assetMap.pluck(listing.BasicInfo.MainPhotoURL || listing.PhotoGallery?.[0]?.mediumImageURL),
      // mci: undefined,
      // mmsi_number: undefined,
      // refit_type: undefined,
      sleep: listing.Accommodations.SleepsValue,
      // specifications: undefined,
      // vessel_top: undefined,
      year_built: listing.BasicInfo.YearBuilt,
      // year_refit: undefined,
      days_on_market: listing.Result.DaysOnMarket,
      tax_status: listing.MiscInfo.TaxesPaid,
    },
    mechanical_equipment: {
      // air_compressor: undefined,
      // air_conditioning: undefined,
      // antifouling_system: undefined,
      // batteries: undefined,
      // battery_chargers: undefined,
      // bow_thruster: undefined,
      // dirty_oil_pump: undefined,
      // emergency_generator: undefined,
      // engine_controls: undefined,
      // engine_mounts: undefined,
      // engine_room_ventilation: undefined,
      // engine_ventilation: undefined,
      // engines_exhaust_system: undefined,
      // fresh_water_makers: undefined,
      // fresh_water_pressure: undefined,
      // fresh_water_treatment: undefined,
      // fuel_filling_pump: undefined,
      // fuel_filters: undefined,
      // fuel_filtration: undefined,
      // fuel_oil_separator: undefined,
      // fuel_transfer_pump: undefined,
      // gear_boxes: undefined,
      // generator_hours: undefined,
      // generators: undefined,
      // grey_water_holding_tanks: undefined,
      // grey_water_system: undefined,
      // hot_and_cold_circulation_pump: undefined,
      // lubrication: undefined,
      // oil_filtration: undefined,
      // oil_pump: undefined,
      // output_electricity: undefined,
      // propellers: undefined,
      // propulsion_type: undefined,
      // rudders: undefined,
      // sewage_system: undefined,
      // shaft_log_and_acking_glands: undefined,
      // shafting: undefined,
      // shore_power: undefined,
      // stabilizers: undefined,
      // steering_system: undefined,
      // stern_thruster: undefined,
      // under_water_lights: undefined,
      // wc: undefined,
      engine_1: {
        approx_hours: listing.Engines[0]?.AppoxHours ? `${listing.Engines[0].AppoxHours}` : undefined,
        date_hours_reg: listing.Engines[0]?.DateHoursReg,
        fuel: listing.Engines[0]?.FuelType,
        horsepower: listing.Engines[0]?.Horsepower ? `${listing.Engines[0].Horsepower}` : undefined,
        last_service: undefined,
        manufacturer: listing.Engines[0]?.Manufacturer,
        model: listing.Engines[0]?.Model,
        serial: undefined,
        type: listing.Engines[0]?.EngineType,
        overhaul_year: listing.Engines[0]?.DateOverhaul,
        engine_year: listing.Engines[1]?.Year,
      },
      engine_2: {
        approx_hours: listing.Engines[1]?.AppoxHours ? `${listing.Engines[1].AppoxHours}` : undefined,
        date_hours_reg: listing.Engines[1]?.DateHoursReg,
        fuel: listing.Engines[1]?.FuelType,
        horsepower: listing.Engines[1]?.Horsepower ? `${listing.Engines[1].Horsepower}` : undefined,
        last_service: undefined,
        manufacturer: listing.Engines[1]?.Manufacturer,
        model: listing.Engines[1]?.Model,
        serial: undefined,
        type: listing.Engines[1]?.EngineType,
        overhaul_year: listing.Engines[1]?.DateOverhaul,
        engine_year: listing.Engines[1]?.Year,
      },
    },
    // navicom_equipment: {
    //   ais: undefined,
    //   auto_pilot: undefined,
    //   charts: undefined,
    //   depth_sounder: undefined,
    //   dgps: undefined,
    //   echo_sounder: undefined,
    //   electronic_chart_system: undefined,
    //   eprib: undefined,
    //   gdmss: undefined,
    //   gps: undefined,
    //   gyrocompass: undefined,
    //   horn: undefined,
    //   inmarsat_c: undefined,
    //   log: undefined,
    //   magnetic_compass: undefined,
    //   navigation_bridge: undefined,
    //   navtex: undefined,
    //   radar: undefined,
    //   radio: undefined,
    //   rudder_angle_indicator_system: undefined,
    //   satcom: undefined,
    //   search_lights: undefined,
    //   ships_computer: undefined,
    //   ships_monitoring: undefined,
    //   ssb: undefined,
    //   telephone_system: undefined,
    //   tender_tracking_system: undefined,
    //   vdr: undefined,
    //   vhf: undefined,
    // },
    speed_capacity_and_weight: {
      cruise_speed: listing.SpeedWeight.CruiseSpeedValue,
      cruise_speed_range: listing.SpeedWeight.CruiseSpeedRange,
      // dirty_lube_oil: undefined,
      fresh_water_capacity: listing.SpeedWeight.WaterCapacity,
      fuel_capacity: listing.SpeedWeight.FuelCapacity,
      fuel_consumption: listing.SpeedWeight.FuelConsumption,
      // grey_black_water_capacity: undefined,
      // lube_oil: undefined,
      max_speed: listing.SpeedWeight.MaxSpeedValue,
      // waster_oil: undefined,
    },
  }

  //
  return brochureListing
}
