export const key: string = 'boats'
export const label: string = 'Boats.com'

//
export type BoatsListing = {
  DocumentID: number
  SalesStatus: string
  NumberOfEngines: number
  SalesRep: {
    Name: string
  }
  CompanyName: string
  Office: {
    PostalAddress: string
    City: string
    State: string
    PostCode: number
    Country: string
    Email: string
    Phone: string
    Name: string
  }
  LastModificationDate: string
  ItemReceivedDate: string
  OriginalPrice: string
  Price: string
  PriceHideInd: boolean
  EmbeddedVideoPresent: boolean
  BoatLocation: {
    BoatCityName: string
    BoatCountryID: string
    BoatStateCode: string
  }
  BoatCityNameNoCaseAlnumOnly: string
  MakeString: string
  MakeStringExact: string
  MakeStringNoCaseAlnumOnly: string
  ModelYear: number
  SaleClassCode: string
  Model: string
  ModelExact: string
  ModelNoCaseAlnumOnly: string
  BoatCategoryCode: string
  BoatName: string
  BoatNameNoCaseAlnumOnly: string
  BuilderName: string
  DesignerName: string
  CruisingSpeedMeasure: string
  PropellerCruisingSpeed: string
  MaximumSpeedMeasure: string
  RangeMeasure: string
  BridgeClearanceMeasure: string
  BeamMeasure: string
  FreeBoardMeasure: string
  CabinHeadroomMeasure: string
  DryWeightMeasure: string
  BallastWeightMeasure: string
  DisplacementMeasure: string
  DisplacementTypeCode: string
  TotalEnginePowerQuantity: string
  DriveTypeCode: string
  BoatKeelCode: string
  ConvertibleSaloonIndicator: boolean
  WindlassTypeCode: string
  DeadriseMeasure: string
  ElectricalCircuitMeasure: string
  TrimTabsIndicator: boolean
  BoatHullMaterialCode: string
  BoatHullID: string
  StockNumber: string
  NominalLength: string
  ListingTitle: string
  TaxStatusCode: string
  IMTTimeStamp: string
  HasBoatHullID: boolean
  IsAvailableForPls: boolean
  NormNominalLength: number
  NormPrice: number
  OptionActiveIndicator: boolean
  Service: [string]
  ProvisioningEventName: [string]
  GeneralBoatDescription: [string]
  BoatClassCode: [string]
  Images: [
    {
      Priority: number
      Caption: string
      Uri: string
      LastModifiedDateTime: number
    },
  ]
  Marketing: [
    {
      OpportunityType: string
      OpportunityMethod: string
      ProgramID: string
      ProgramDescription: string
      ProgramOffer: string
      PublicationID: string
      MarketingID: string
    },
  ]
  BoatClassCodeNoCaseAlnumOnly: [string]
  AdditionalDetailDescription: [string]
}
