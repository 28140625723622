import { AssetMap, BrochureListing, Chronicle, ListingInfo } from '@beacon/common/types'
import { asBrochureListing as ahoyCMSToBrochureListing } from '../src/provider/ahoy-cms/asBrochureListing'
import { asBrochureListing as ankorToBrochureListing } from '../src/provider/ankor/asBrochureListing'
import { asBrochureListing as boatsToBrochureListing } from '../src/provider/boats/asBrochureListing'
import { asBrochureListing as boatsOnlineToBochureListing } from '../src/provider/boats-online/asBrochureListing'
import { asBrochureListing as yatcoToBrochureListing } from '../src/provider/yatco/asBrochureListing'
import * as AhoyCMSTypes from '../src/provider/ahoy-cms/types'
import * as AnkorTypes from '../src/provider/ankor/types'
import * as BoatsTypes from '../src/provider/boats/types'
import * as BoatsOnlineTypes from '../src/provider/boats-online/types'
import * as YatcoTypes from '../src/provider/yatco/types'

//
// Services to be used by other projects/modules; hence the "complicated" imports.
// Adding a new provider? Add the linkages here.
//

//
const labels: Record<string, string> = {
  [AhoyCMSTypes.key]: AhoyCMSTypes.label,
  [AnkorTypes.key]: AnkorTypes.label,
  [BoatsTypes.key]: BoatsTypes.label,
  [BoatsOnlineTypes.key]: BoatsOnlineTypes.label,
  [YatcoTypes.key]: YatcoTypes.label,
}

/**
 * Convert a listing to a brochure listing.
 *
 * @param listing
 * @param listingInfo
 * @param chronicle
 * @param assetMap
 * @returns
 */
export const asBrochureListing = (
  listing: unknown,
  listingInfo: ListingInfo,
  chronicle: Chronicle,
  assetMap: AssetMap,
): BrochureListing => {
  // these function calls do not tree shake well into other modules because of their linked imports.
  // return getProvider(listingInfo.provider).asBrochureListing(listing as any, listingInfo, chronicle, assetMap)
  // return AhoyCMSProvider.asBrochureListing(listing as any, listingInfo, chronicle, assetMap)

  // so we do it manually.
  switch (listingInfo.provider) {
    case AhoyCMSTypes.key:
      return ahoyCMSToBrochureListing(listing as any, listingInfo, chronicle, assetMap)
    case AnkorTypes.key:
      return ankorToBrochureListing(listing as any, listingInfo, chronicle, assetMap)
    case BoatsTypes.key:
      return boatsToBrochureListing(listing as any, listingInfo, chronicle, assetMap)
    case BoatsOnlineTypes.key:
      return boatsOnlineToBochureListing(listing as any, listingInfo, chronicle, assetMap)
    case YatcoTypes.key:
      return yatcoToBrochureListing(listing as any, listingInfo, chronicle, assetMap)
  }

  throw new Error('unknown provider')
}

/**
 * Get the label for a provider.
 * @param key
 * @returns
 */
export const getProviderLabel = (key: string): string | undefined => {
  return labels[key] || undefined
}
