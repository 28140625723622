import { logger } from '@beacon/common/lang/log'
import { AssetMap, BrochureListing, Chronicle, ListingInfo } from '@beacon/common/types'
import { AhoyCMSListing } from './types'

const log = logger({ package: 'provider', provider_name: 'ahoy-cms', f: 'asBrochureListing' })

/**
 *
 * @param content
 * @returns
 */
export const asBrochureListing = (
  listing: AhoyCMSListing,
  _listingInfo: ListingInfo,
  _chronicle: Chronicle,
  assetMap: AssetMap,
): BrochureListing => {
  log.trace({ c: listing }, 'incoming listing content')

  // this in intentionally boilerplate to be able to see the changes.
  // ahoy-cms and the brochure should start to diverge.

  const brochureListing = {
    //
    name: listing.name,
    title: listing.title,
    //
    auction_banner: listing.auction_banner,
    auction_date: listing.auction_date,
    auction_location: listing.auction_location,
    best_buy: listing.best_buy,
    bidding_link: listing.bidding_link,
    images: assetMap.map(listing.images),
    is_central_agency: listing.is_central_agency,
    new_listing: listing.new_listing,
    price_drop: listing.price_drop,
    sold_banner: Boolean(listing.sold_banner).valueOf(),
    accommodation: {
      accommodation: listing.accommodation,
    },
    entertainment_and_av_equipment: listing.entertainment_and_av_equipment,
    galley_and_laundry_equipment: listing.galley_and_laundry_equipment,
    safety_and_security_equipment: listing.safety_and_security_equipment,
    refits_and_additional: listing.refits_and_additional,
    toys_and_tenders: listing.toys_and_tenders,
    //
    broker_details: {
      image: assetMap.pluck(listing.broker_details?.image),
      is_broker: listing.broker_details?.is_broker,
      job_title: listing.broker_details?.job_title,
      name: listing.broker_details?.name,
      personal_blurb: listing.broker_details?.personal_blurb,
      phone_number: listing.broker_details?.phone_number,
    },
    hull_and_deck: {
      date_keel_laid: listing.hull_and_deck?.date_keel_laid,
      deck_material: listing.hull_and_deck?.deck_material,
      exterior_designer: listing.hull_and_deck?.exterior_designer,
      hin: listing.hull_and_deck?.hin,
      hull_colour: listing.hull_and_deck?.hull_colour,
      hull_configuration: listing.hull_and_deck?.hull_configuration,
      hull_designer: listing.hull_and_deck?.hull_designer,
      hull_material: listing.hull_and_deck?.hull_material,
      hull_number: listing.hull_and_deck?.hull_number,
      interior_designer: listing.hull_and_deck?.interior_designer,
      superstructure_material: listing.hull_and_deck?.superstructure_material,
      warranty: listing.hull_and_deck?.warranty,
    },
    main_spec: {
      //
      length_meters: listing.main_spec.length_meters,
      model: listing.main_spec.model,
      price: listing.main_spec.price,
      description: listing.main_spec.description,
      detailed_description: undefined,
      year_built: listing.main_spec.year_built,
      //
      beam: listing.main_spec.beam,
      builder: listing.main_spec.builder,
      cabins: listing.main_spec.cabins,
      type: listing.main_spec.category,
      classification: listing.main_spec.classification,
      construction: listing.main_spec.construction,
      crew: listing.main_spec.crew,
      decks: listing.main_spec.decks,
      draft_length: listing.main_spec.draft_length,
      exterior_designer: listing.main_spec.exterior_designer,
      flag: listing.main_spec.flag,
      gross_tonnage: listing.main_spec.gross_tonnage,
      imo_number: listing.main_spec.imo_number,
      interior_designer: listing.main_spec.interior_designer,
      ism: listing.main_spec.ism,
      lying: listing.main_spec.lying,
      main_image: assetMap.pluck(listing.main_spec?.main_image),
      mci: listing.main_spec.mci,
      mmsi_number: listing.main_spec.mmsi_number,
      refit_type: listing.main_spec.refit_type,
      sleep: listing.main_spec.sleep,
      specifications: listing.main_spec.specifications,
      vessel_top: listing.main_spec.vessel_top,
      year_refit: listing.main_spec.year_refit,
    },
    mechanical_equipment: {
      air_compressor: listing.mechanical_equipment?.air_compressor,
      air_conditioning: listing.mechanical_equipment?.air_conditioning,
      antifouling_system: listing.mechanical_equipment?.antifouling_system,
      batteries: listing.mechanical_equipment?.batteries,
      battery_chargers: listing.mechanical_equipment?.battery_chargers,
      bow_thruster: listing.mechanical_equipment?.bow_thruster,
      dirty_oil_pump: listing.mechanical_equipment?.dirty_oil_pump,
      emergency_generator: listing.mechanical_equipment?.emergency_generator,
      engine_controls: listing.mechanical_equipment?.engine_controls,
      engine_mounts: listing.mechanical_equipment?.engine_mounts,
      engine_room_ventilation: listing.mechanical_equipment?.engine_room_ventilation,
      engine_ventilation: listing.mechanical_equipment?.engine_ventilation,
      engines_exhaust_system: listing.mechanical_equipment?.engines_exhaust_system,
      fresh_water_makers: listing.mechanical_equipment?.fresh_water_makers,
      fresh_water_pressure: listing.mechanical_equipment?.fresh_water_pressure,
      fresh_water_treatment: listing.mechanical_equipment?.fresh_water_treatment,
      fuel_filling_pump: listing.mechanical_equipment?.fuel_filling_pump,
      fuel_filters: listing.mechanical_equipment?.fuel_filters,
      fuel_filtration: listing.mechanical_equipment?.fuel_filtration,
      fuel_oil_separator: listing.mechanical_equipment?.fuel_oil_separator,
      fuel_transfer_pump: listing.mechanical_equipment?.fuel_transfer_pump,
      gear_boxes: listing.mechanical_equipment?.gear_boxes,
      generator_hours: listing.mechanical_equipment?.generator_hours,
      generators: listing.mechanical_equipment?.generators,
      grey_water_holding_tanks: listing.mechanical_equipment?.grey_water_holding_tanks,
      grey_water_system: listing.mechanical_equipment?.grey_water_system,
      hot_and_cold_circulation_pump: listing.mechanical_equipment?.hot_and_cold_circulation_pump,
      lubrication: listing.mechanical_equipment?.lubrication,
      oil_filtration: listing.mechanical_equipment?.oil_filtration,
      oil_pump: listing.mechanical_equipment?.oil_pump,
      output_electricity: listing.mechanical_equipment?.output_electricity,
      propellers: listing.mechanical_equipment?.propellers,
      propulsion_type: listing.mechanical_equipment?.propulsion_type,
      rudders: listing.mechanical_equipment?.rudders,
      sewage_system: listing.mechanical_equipment?.sewage_system,
      shaft_log_and_acking_glands: listing.mechanical_equipment?.shaft_log_and_acking_glands,
      shafting: listing.mechanical_equipment?.shafting,
      shore_power: listing.mechanical_equipment?.shore_power,
      stabilizers: listing.mechanical_equipment?.stabilizers,
      steering_system: listing.mechanical_equipment?.steering_system,
      stern_thruster: listing.mechanical_equipment?.stern_thruster,
      under_water_lights: listing.mechanical_equipment?.under_water_lights,
      wc: listing.mechanical_equipment?.wc,
      engine_1: {
        approx_hours: listing.mechanical_equipment?.engine_1?.approx_hours,
        date_hours_reg: listing.mechanical_equipment?.engine_1?.date_hours_reg,
        fuel: listing.mechanical_equipment?.engine_1?.fuel,
        horsepower: listing.mechanical_equipment?.engine_1?.horsepower,
        last_service: listing.mechanical_equipment?.engine_1?.last_service,
        manufacturer: listing.mechanical_equipment?.engine_1?.manufacturer,
        model: listing.mechanical_equipment?.engine_1?.model,
        serial: listing.mechanical_equipment?.engine_1?.serial,
        type: listing.mechanical_equipment?.engine_1?.type,
      },
      engine_2: {
        approx_hours: listing.mechanical_equipment?.engine_2?.approx_hours,
        date_hours_reg: listing.mechanical_equipment?.engine_2?.date_hours_reg,
        fuel: listing.mechanical_equipment?.engine_2?.fuel,
        horsepower: listing.mechanical_equipment?.engine_2?.horsepower,
        last_service: listing.mechanical_equipment?.engine_2?.last_service,
        manufacturer: listing.mechanical_equipment?.engine_2?.manufacturer,
        model: listing.mechanical_equipment?.engine_2?.model,
        serial: listing.mechanical_equipment?.engine_2?.serial,
        type: listing.mechanical_equipment?.engine_2?.type,
      },
    },
    navicom_equipment: {
      ais: listing.navicom_equipment?.ais,
      auto_pilot: listing.navicom_equipment?.auto_pilot,
      charts: listing.navicom_equipment?.charts,
      depth_sounder: listing.navicom_equipment?.depth_sounder,
      dgps: listing.navicom_equipment?.dgps,
      echo_sounder: listing.navicom_equipment?.echo_sounder,
      electronic_chart_system: listing.navicom_equipment?.electronic_chart_system,
      eprib: listing.navicom_equipment?.eprib,
      gdmss: listing.navicom_equipment?.gdmss,
      gps: listing.navicom_equipment?.gps,
      gyrocompass: listing.navicom_equipment?.gyrocompass,
      horn: listing.navicom_equipment?.horn,
      inmarsat_c: listing.navicom_equipment?.inmarsat_c,
      log: listing.navicom_equipment?.log,
      magnetic_compass: listing.navicom_equipment?.magnetic_compass,
      navigation_bridge: listing.navicom_equipment?.navigation_bridge,
      navtex: listing.navicom_equipment?.navtex,
      radar: listing.navicom_equipment?.radar,
      radio: listing.navicom_equipment?.radio,
      rudder_angle_indicator_system: listing.navicom_equipment?.rudder_angle_indicator_system,
      satcom: listing.navicom_equipment?.satcom,
      search_lights: listing.navicom_equipment?.search_lights,
      ships_computer: listing.navicom_equipment?.ships_computer,
      ships_monitoring: listing.navicom_equipment?.ships_monitoring,
      ssb: listing.navicom_equipment?.ssb,
      telephone_system: listing.navicom_equipment?.telephone_system,
      tender_tracking_system: listing.navicom_equipment?.tender_tracking_system,
      vdr: listing.navicom_equipment?.vdr,
      vhf: listing.navicom_equipment?.vhf,
    },
    speed_capacity_and_weight: {
      cruise_speed: listing.speed_capacity_and_weight?.cruise_speed,
      cruise_speed_range: listing.speed_capacity_and_weight?.cruise_speed_range,
      dirty_lube_oil: listing.speed_capacity_and_weight?.dirty_lube_oil,
      fresh_water_capacity: listing.speed_capacity_and_weight?.fresh_water_capacity,
      fuel_capacity: listing.speed_capacity_and_weight?.fuel_capacity,
      fuel_consumption: listing.speed_capacity_and_weight?.fuel_consumption,
      grey_black_water_capacity: listing.speed_capacity_and_weight?.grey_black_water_capacity,
      lube_oil: listing.speed_capacity_and_weight?.lube_oil,
      max_speed: listing.speed_capacity_and_weight?.max_speed,
      waster_oil: listing.speed_capacity_and_weight?.waster_oil,
    },
  }

  log.trace({ listing: brochureListing }, 'returning listing')
  return brochureListing
}
