export const key: string = 'ankor'
export const label: string = 'Ankor'

//
export type AnkorListing = {
  uri: string
  vessel?: {
    blueprint?: {
      images?: string[]
    }
  }
}
