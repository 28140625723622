import { logger } from '@beacon/common/lang/log'
import { Asset } from '@beacon/common/types'
import { YatcoListing } from './types'

const log = logger({ package: 'provider', provider_name: 'yatco', f: 'extractAssets' })

/**
 * Extract all the asset urls.
 *
 * @param listing
 * @returns
 */
export const extractAssets = async (listing: YatcoListing): Promise<Asset[]> => {
  //
  const srcs = extractAssetUrls(listing)
  const assets = Array.from(srcs)
    // filter out empty strings
    .filter((src) => !!src)
    .map((src) => ({ src }))
  log.trace({ assets }, 'extractAssets')
  return assets
}

// extract the asset urls
export const extractAssetUrls = (listing: YatcoListing): string[] => {
  return Array.from(
    new Set(
      [
        listing.Result?.MainPhotoMedURL,
        listing.BasicInfo.MainPhotoURL,
        ...listing.PhotoGallery.map((img) => img.mediumImageURL),
        ...listing.Layouts.map((img) => img.mediumImageURL),
      ].filter((src) => !!src),
    ),
  )
}
