import { logger } from '@beacon/common/lang/log'
import { AssetMap, BrochureListing, Chronicle, ListingInfo } from '@beacon/common/types'
import { imageUrl } from './_helpers'
import { AnkorListing } from './types'

const log = logger({ package: 'provider', provider_name: 'ankor', f: 'asBrochureListing' })

/**
 *
 * @param content
 * @returns
 */
export const asBrochureListing = (
  content: AnkorListing,
  _listingInfo: ListingInfo,
  _chronicle: Chronicle,
  assetMap: AssetMap,
): BrochureListing => {
  log.trace({ content }, 'incoming listing content')
  const vessel: any = content?.vessel
  if (!content || !vessel) {
    throw new Error('no vessel found in content')
  }

  // TODO: add more fields
  return {
    name: vessel.blueprint?.name,
    images: vessel.blueprint?.images?.map((src: string) => assetMap.pluck(imageUrl(content.uri, src))),
    main_spec: {
      length_meters: vessel.blueprint?.length,
      model: vessel.blueprint?.model,
      year_built: vessel.blueprint?.builtYear,
      year_refit: vessel.blueprint?.refitYear,
      cabins: vessel.blueprint?.cabins,
      flag: vessel.blueprint?.flag,
    },
  }
}
