<script setup lang="ts">
import { inject, onBeforeUnmount, onMounted, ref } from 'vue'

const queueMetrics = ref<any>(null)
let fetchTimer: any
let authToken: string

// Create the search client.
const authenticationContext: any = inject('authenticationContext')!

const emit = defineEmits(['showSpinner'])

onMounted(async () => {
  //
  // get the auth token and then fire off a data fetch.
  await authenticationContext
    .getToken() //
    .then((token: any) => {
      authToken = token
      fetchData()
    })

  // refetch the data every 47 seconds
  fetchTimer = setInterval(() => {
    fetchData()
  }, 47147)
})

onBeforeUnmount(() => {
  // clear the timer
  clearInterval(fetchTimer)
})

//
// format a number to a string or dash
const n0 = (n: number) => (n ? n.toFixed(0) : '&ndash;')

//
// get the data from the server; update the queueMetrics ref and the view will update.
const fetchData = async () => {
  //
  emit('showSpinner', true)
  queueMetrics.value = await fetch('/api/status/queue/all', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  }) //
    .then((response) => {
      emit('showSpinner', false)
      return response.json()
    })
}
</script>
<template>
  <main>
    <div class="relative sm:rounded-lg border mx-4 overflow-auto">
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" rowspan="2" class="px-6 py-3 border-b border-gray-500 align-bottom">Queue Name</th>
            <th scope="col" colspan="4" class="px-6 py-3 text-center border-l border-gray-500 bg-blue-200">
              # Writes To
            </th>
            <th scope="col" colspan="4" class="px-6 py-3 text-center border-l border-gray-500 bg-blue-200">
              # Reads From
            </th>
            <th scope="col" colspan="4" class="px-6 py-3 text-center border-l border-gray-500 bg-blue-200">
              # msgs waiting
            </th>
            <th scope="col" colspan="4" class="px-6 py-3 text-center border-l border-gray-500 bg-blue-200">
              # msgs delayed
            </th>
          </tr>
          <tr>
            <!-- writes -->
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-l border-gray-500">1m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">5m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">15m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">60m</th>
            <!-- reads -->
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-l border-gray-500">1m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">5m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">15m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">60m</th>
            <!-- backlog -->
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-l border-gray-500">1m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">5m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">15m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">24h</th>
            <!-- delay backlog -->
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-l border-gray-500">1m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">5m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">15m</th>
            <th scope="col" class="min-w-16 text-right lowercase pe-4 py-3 border-b border-gray-500">24h</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b dark:border-gray-700" v-for="queue in queueMetrics" :key="queue.id">
            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {{ queue.name }}
            </th>
            <!-- writes -->
            <td class="text-right pe-4 border-l border-gray-500" v-html="n0(queue.writes['count_1m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.writes['count_5m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.writes['count_15m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.writes['count_1h'])"></td>
            <!-- reads -->
            <td class="text-right pe-4 border-l border-gray-500" v-html="n0(queue.reads['count_1m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.reads['count_5m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.reads['count_15m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.reads['count_1h'])"></td>
            <!-- backlog -->
            <td class="text-right pe-4 border-l border-gray-500" v-html="n0(queue.backlog['avg_1m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.backlog['avg_5m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.backlog['avg_15m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.backlog['max_24h'])"></td>
            <!-- delay backlog -->
            <td class="text-right pe-4 border-l border-gray-500" v-html="n0(queue.delayedBacklog['avg_1m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.delayedBacklog['avg_5m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.delayedBacklog['avg_15m'])"></td>
            <td class="text-right pe-4" v-html="n0(queue.delayedBacklog['max_24h'])"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>
